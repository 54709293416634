<template>
  <b-card-header header-tag="div" role="tab">
    <div>
      <h5 class="mb-0 d-flex align-items-center">
        <div class="flex-grow-1">
          <button v-b-toggle="`${id}`" class="btn btn-link collapsed pl-0 pr-0">
            <div class="row" v-if="!_.isEmpty(record.image)">
              <div class="col-3">
                <img :alt="record.image.filename" :src="getImageURL(record.image.url)" class="w-100" />
              </div>
              <div v-html="headerText" class="col-9"></div>
            </div>
            <div v-html="headerText" v-else></div>
          </button>
        </div>
        <span v-if="(modelName != 'Admin' || currentUser.role == superAdmin ) && modelName != 'systemFeedback'">
          <a v-if="isContent(record) && !record.published" href="#">
            <i class="fa fa-check master-link m-r-30" @click="publish(record)"></i>
          </a>
          <a v-if="isContent(record) && record.published" href="#">
            <i class="fa fa-close master-link m-r-30" @click="unpublish(record)"></i>
          </a>
          <router-link class="m-r-30" :to="{ name: modelName + 'Edit', params: { id: record.id }}">
            <i class="fa fa-pencil master-link"></i>
          </router-link>
          <router-link 
            v-if="modelName != 'Admin' || record.id != currentUser.id"
            v-b-modal.modal-delete-record
            :to="{ path: '#'}" >
            <i class="fa fa-trash master-link m-r-30" @click="sendRecord(record.id)"></i>
          </router-link>
        </span>
        <span class="badge badge-primary pull-right m-r-30"
              v-if="modelName == 'systemFeedback' && !record.seen">
          {{ $t("actions.new") }}
        </span>
      </h5>
    </div>
  </b-card-header>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { ServiceProvider } from 'models/service_provider';
import { User } from 'models/user';
import { FrequentQuestion } from 'models/frequent_question';
import { Admin } from 'models/admin';
import { adminRoles } from 'constants';
import { SystemFeedback } from 'models/system_feedback';
import { Quiz } from 'models/quiz';
import { Article } from 'models/article';
import { Audio } from 'models/audio';
import { Video } from 'models/video';

export default {
  data() {
    return {
      moment,
      superAdmin: adminRoles.SUPER
    };
  },
  props: {
    id: Number,
    record: Object,
    modelName: String
  },
  methods: {
    sendRecord(recordId) {
      this.$emit('sendRecord', recordId);
    },
    publish(record) {
      this.$emit('publish', record);
    },
    unpublish(record) {
      this.$emit('unpublish', record);
    },
    isContent(record) {
      return record instanceof Article ||  record instanceof Audio || record instanceof Video;
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    }),
    headerText() {
      if (this.record instanceof ServiceProvider) {
        return this.record.header + "<span class='ml-3 badge badge-primary'>" + this.record.pendingReviewsCount + '</span>';
      } else if (this.record instanceof User || this.record instanceof Admin) {
        return this.record.name;
      } else if (this.record instanceof FrequentQuestion) {
        return this.record.question;
      } else if (this.record instanceof SystemFeedback) {
        return this.record.content;
      } else if (this.record instanceof Quiz) {
        return this.record.title;
      } else {
        return this.record.title + '<br>' + moment(this.record.createdAt).format('DD/MM/YYYY') +
            '<br>' + this.$t('views.constants.viewsCount') + ':' + (this.record.viewsCount || 0);
      }
    }
  }
};
</script>

<style scoped>
  .w-100 {
    max-width: 100%;
  }
</style>
