<template>
  <b-card no-body>
    <b-card-header header-tag="div" role="tab">
      <h5 class="mb-0">
        <b-button class="btn btn-link pl-0"
                  block v-b-toggle.collapseicon>{{ $t('views.constants.filter') }}</b-button>
      </h5>
    </b-card-header>
    <div class="card-body filter-cards-view animate-chk">
      <b-collapse id="collapseicon" visible role="tabpanel">
        <app-form :fields="tagsChecklist ? searchFields['tags'] : searchFields"
                  :submitButton="submitButton"
                  @submitted="search"/>
      </b-collapse>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    searchFields: Object,
    submitButton: Object,
    tagsChecklist: Boolean
  },
  methods: {
    search(data) {
      this.$emit('searchRecords', data);
    }
  }
};
</script>

<style scoped>

</style>
