<template>
  <b-modal id="modal-delete-record" :title="$tc(`models.${model}.entity`, 1)"
			     :ok-title="$tc('actions.confirm')" class="theme-modal" @ok="deleteModel">
    <p>{{ $t('actions.deleteConfirmation', { entity: $tc(`models.${model}.entity`, 1) })}}</p>
  </b-modal>
</template>
<script>
export default {
  name: 'Modal',
  props: {
  	model: {
  		type: String,
  		required: true
  	}
  },
  methods: {
  	deleteModel() {
    	this.$emit('confirm-deletion');
  	}
  }
};
</script>
