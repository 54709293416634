<template>
  <div class="d-flex justify-content-between align-items-center">
    <Breadcrumbs :title="$tc('models.' + entityName + '.entity', 2)"/>
    <router-link :to="{ name: routerName }" v-if="routerName">
      <h4 :class="paddingOrientation"><i class="fa fa-plus"></i></h4>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    entityName: String,
    routerName: String
  },
  computed: {
    paddingOrientation() {
      return this.$store.getters.layout.settings.layoutType === 'ltr' ? 'p-r-30' : 'p-l-30';
    }
  }
};
</script>
