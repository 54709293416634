import ListHeader from '../components/shared/ListHeader';
import FilterCard from '../components/shared/FilterCard';
import Modal from '../components/shared/Modal';
import CardHeader from '../components/shared/CardHeader';

export default {
  data() {
    return {
      pages: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['createdAt'],
        sortDesc: [true],
        mustSort: ['true'],
        multiSort: false
      },
      selectedRecord: 0
    };
  },
  computed: {
    searchButton() {
      return {
        label: this.$t('actions.find', {entity: this.$tc('models.' + this.modelName + '.entity', 2)}),
        block: true
      };
    },
    countNotZero() {
      return this.records.totalCount > 0;
    }
  },
  methods: {
    publish(record) {
      this.publishRecord(record).then(() => {
        record.published = true;
      });
    },
    unpublish(record) {
      this.unpublishRecord(record).then(() => {
        record.published = false;
      });
    },
    deleteRecord() {
      this.destroyRecord(this.selectedRecord).then(() => {
        this.fetchAndPaginateRecords();
      });
    },
    sendRecord(id) {
      this.selectedRecord = id;
    },
    fetchAndPaginateRecords() {
      this.getRecords(this.options).then(() => {
        this.pages = this.setPageNumbers(this.records.totalCount, this.options.itemsPerPage);
      });
    },
    search(data) {
      const filters = this.getFilters(data);
      this.options.page = 1;
      const options = {...this.options, filterBy: filters};
      this.getRecords(options).then(() => {
        this.pages = this.setPageNumbers(this.records.totalCount, this.options.itemsPerPage);
      });
    },
    paginate(pageNum) {
      this.options.page = pageNum;
      this.getRecords(this.options);
    }
  },
  created() {
    this.fetchAndPaginateRecords();
  },
  components: {
    ListHeader,
    FilterCard,
    Modal,
    CardHeader
  }
};
